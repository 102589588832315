import { tv } from 'tailwind-variants';

export const SNACKBAR_CLASS_NAME = tv({
  base: 'tw-grid tw-w-[400px] tw-grid-cols-[20px_minmax(0,_1fr)_20px] tw-gap-2 tw-rounded tw-px-4 tw-py-[18px] tw-shadow-[0_4px_8px_0_rgba(0,0,0,0.1)]',
  variants: {
    theme: {
      success: 'tw-bg-[#F2FFEF] tw-outline tw-outline-1 tw-outline-primary-200',
      warning: 'tw-bg-[#FFF9E7] tw-outline tw-outline-1 tw-outline-yellow-500',
      info: 'tw-bg-[#EBF0FF] tw-outline tw-outline-1 tw-outline-[#3F6AFF]',
      error: 'tw-bg-[#FFECEC] tw-outline tw-outline-1 tw-outline-red-500',
    },
  },
});
