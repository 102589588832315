import { useSearchParams } from '@remix-run/react';
import { useAtom } from 'jotai';
import { useEffect, type ReactNode } from 'react';

import { LoadingLayer } from '~/components/ui/LoadingLayer';
import { userDataAtom } from '~/stores/atoms/user';

type AuthProviderProps = {
  children: ReactNode;
};

// TODO: 一時的な遅延処理。ユーザー情報取得APIを呼び出すようになったら削除
const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [userData, setUserData] = useAtom(userDataAtom);

  const [searchParams] = useSearchParams();
  // TODO: 暫定的に静的ページでロールを切り替えるのに使用、応募者・募集者パスが分かれるようになったら削除
  const roleParam = searchParams.get('role') ?? undefined;

  const fetchUserData = async () => {
    await sleep(1000);
    setUserData({
      type: 'user',
      userRole: roleParam === 'recruiter' ? 'recruiter' : 'applicant',
    });
  };

  // TODO: トークンある場合、ユーザー情報取得APIを呼び出して更新するようにする
  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ユーザーのステータス取得中はローディング表示
  if (userData === undefined) {
    return (
      <div>
        <LoadingLayer loading />
      </div>
    );
  }
  return children;
};
