import { QueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { notifyError } from '../react-hot-toast';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      networkMode: 'offlineFirst',
      throwOnError: (error) => {
        if (error instanceof AxiosError) {
          if (error?.response?.status === 403) {
            notifyError({
              content: error.response.statusText,
            });
          }
        }
        return false; // ErrorBoundaryでページ表示をしない（現在表示のページでtoastを表示するため）のでfalse
      },
    },
    mutations: {
      networkMode: 'offlineFirst',
    },
  },
});
