import {
  Links,
  Meta,
  Outlet,
  Scripts,
  useRouteError,
  isRouteErrorResponse,
  ScrollRestoration,
} from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { ReactNode } from 'react';

import './styles/globals.css';

import { LoadingLayer } from './components/ui/LoadingLayer';
import { AppProvider } from './providers/app';

export const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <html lang="ja">
      <head>
        <title>Remix App</title>
        <link
          rel="icon"
          href="/favicon.ico"
          type="image/svg+xml"
          sizes="48x48"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@200..700&display=swap"
        />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex" />
        <Meta />
        <Links />
      </head>
      <body className="tw-text-text-body-middle">
        {children}
        <Scripts />
        <ScrollRestoration />
      </body>
    </html>
  );
};

export const ErrorBoundary = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error) && error.status === 404) {
    return <div>404</div>;
  }

  captureRemixErrorBoundaryError(error);
  return <div>500</div>;
};

export default function App() {
  return (
    <AppProvider>
      <Outlet />
    </AppProvider>
  );
}

export function HydrateFallback() {
  return <LoadingLayer loading />;
}
