import { clsx } from 'clsx';

import type { SnackbarProps } from './type';

import { IconClose } from '~/components/icons';

import { SNACKBAR_CLASS_NAME } from './const';

// NOTE: アイコンが他で使われる場合は、コンポーネントにして共通化する
const iconVariants = {
  success: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M7.83366 12.8332L13.7087 6.95817L12.542 5.7915L7.83366 10.4998L5.45866 8.12484L4.29199 9.2915L7.83366 12.8332ZM9.00033 17.3332C7.84755 17.3332 6.76421 17.1144 5.75033 16.6769C4.73644 16.2394 3.85449 15.6457 3.10449 14.8957C2.35449 14.1457 1.76074 13.2637 1.32324 12.2498C0.885742 11.2359 0.666992 10.1526 0.666992 8.99984C0.666992 7.84706 0.885742 6.76373 1.32324 5.74984C1.76074 4.73595 2.35449 3.854 3.10449 3.104C3.85449 2.354 4.73644 1.76025 5.75033 1.32275C6.76421 0.885254 7.84755 0.666504 9.00033 0.666504C10.1531 0.666504 11.2364 0.885254 12.2503 1.32275C13.2642 1.76025 14.1462 2.354 14.8962 3.104C15.6462 3.854 16.2399 4.73595 16.6774 5.74984C17.1149 6.76373 17.3337 7.84706 17.3337 8.99984C17.3337 10.1526 17.1149 11.2359 16.6774 12.2498C16.2399 13.2637 15.6462 14.1457 14.8962 14.8957C14.1462 15.6457 13.2642 16.2394 12.2503 16.6769C11.2364 17.1144 10.1531 17.3332 9.00033 17.3332Z"
        fill="#2BA15A"
      />
    </svg>
  ),
  warning: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <mask
        id="mask0_1486_1780"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1486_1780)">
        <path
          d="M10.0003 14.1665C10.2364 14.1665 10.4344 14.0866 10.5941 13.9269C10.7538 13.7672 10.8337 13.5693 10.8337 13.3332C10.8337 13.0971 10.7538 12.8991 10.5941 12.7394C10.4344 12.5797 10.2364 12.4998 10.0003 12.4998C9.76421 12.4998 9.5663 12.5797 9.40658 12.7394C9.24685 12.8991 9.16699 13.0971 9.16699 13.3332C9.16699 13.5693 9.24685 13.7672 9.40658 13.9269C9.5663 14.0866 9.76421 14.1665 10.0003 14.1665ZM9.16699 10.8332H10.8337V5.83317H9.16699V10.8332ZM10.0003 18.3332C8.84755 18.3332 7.76421 18.1144 6.75033 17.6769C5.73644 17.2394 4.85449 16.6457 4.10449 15.8957C3.35449 15.1457 2.76074 14.2637 2.32324 13.2498C1.88574 12.2359 1.66699 11.1526 1.66699 9.99984C1.66699 8.84706 1.88574 7.76373 2.32324 6.74984C2.76074 5.73595 3.35449 4.854 4.10449 4.104C4.85449 3.354 5.73644 2.76025 6.75033 2.32275C7.76421 1.88525 8.84755 1.6665 10.0003 1.6665C11.1531 1.6665 12.2364 1.88525 13.2503 2.32275C14.2642 2.76025 15.1462 3.354 15.8962 4.104C16.6462 4.854 17.2399 5.73595 17.6774 6.74984C18.1149 7.76373 18.3337 8.84706 18.3337 9.99984C18.3337 11.1526 18.1149 12.2359 17.6774 13.2498C17.2399 14.2637 16.6462 15.1457 15.8962 15.8957C15.1462 16.6457 14.2642 17.2394 13.2503 17.6769C12.2364 18.1144 11.1531 18.3332 10.0003 18.3332Z"
          fill="#FFD362"
        />
      </g>
    </svg>
  ),
  info: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <mask
        id="mask0_1486_1812"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1486_1812)">
        <path
          d="M9.16699 14.1665H10.8337V9.1665H9.16699V14.1665ZM10.0003 7.49984C10.2364 7.49984 10.4344 7.41998 10.5941 7.26025C10.7538 7.10053 10.8337 6.90262 10.8337 6.6665C10.8337 6.43039 10.7538 6.23248 10.5941 6.07275C10.4344 5.91303 10.2364 5.83317 10.0003 5.83317C9.76421 5.83317 9.5663 5.91303 9.40658 6.07275C9.24685 6.23248 9.16699 6.43039 9.16699 6.6665C9.16699 6.90262 9.24685 7.10053 9.40658 7.26025C9.5663 7.41998 9.76421 7.49984 10.0003 7.49984ZM10.0003 18.3332C8.84755 18.3332 7.76421 18.1144 6.75033 17.6769C5.73644 17.2394 4.85449 16.6457 4.10449 15.8957C3.35449 15.1457 2.76074 14.2637 2.32324 13.2498C1.88574 12.2359 1.66699 11.1526 1.66699 9.99984C1.66699 8.84706 1.88574 7.76373 2.32324 6.74984C2.76074 5.73595 3.35449 4.854 4.10449 4.104C4.85449 3.354 5.73644 2.76025 6.75033 2.32275C7.76421 1.88525 8.84755 1.6665 10.0003 1.6665C11.1531 1.6665 12.2364 1.88525 13.2503 2.32275C14.2642 2.76025 15.1462 3.354 15.8962 4.104C16.6462 4.854 17.2399 5.73595 17.6774 6.74984C18.1149 7.76373 18.3337 8.84706 18.3337 9.99984C18.3337 11.1526 18.1149 12.2359 17.6774 13.2498C17.2399 14.2637 16.6462 15.1457 15.8962 15.8957C15.1462 16.6457 14.2642 17.2394 13.2503 17.6769C12.2364 18.1144 11.1531 18.3332 10.0003 18.3332Z"
          fill="#3F6AFF"
        />
      </g>
    </svg>
  ),
  error: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <mask
        id="mask0_1486_1784"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1486_1784)">
        <path
          d="M0.833008 17.4998L9.99967 1.6665L19.1663 17.4998H0.833008ZM9.99967 14.9998C10.2358 14.9998 10.4337 14.92 10.5934 14.7603C10.7531 14.6005 10.833 14.4026 10.833 14.1665C10.833 13.9304 10.7531 13.7325 10.5934 13.5728C10.4337 13.413 10.2358 13.3332 9.99967 13.3332C9.76356 13.3332 9.56565 13.413 9.40592 13.5728C9.2462 13.7325 9.16634 13.9304 9.16634 14.1665C9.16634 14.4026 9.2462 14.6005 9.40592 14.7603C9.56565 14.92 9.76356 14.9998 9.99967 14.9998ZM9.16634 12.4998H10.833V8.33317H9.16634V12.4998Z"
          fill="#F33131"
        />
      </g>
    </svg>
  ),
};

export const Snackbar = ({
  description,
  children,
  theme = 'success',
  onClose,
}: SnackbarProps) => {
  return (
    <div className={clsx(SNACKBAR_CLASS_NAME({ theme }))}>
      {iconVariants[theme]}
      <div className={clsx('tw-break-words')}>
        <p className={clsx('tw-text-ui14 tw-font-semibold tw-text-[#1A1A1A]')}>
          {children}
        </p>
        {description && (
          <p
            className={clsx(
              'tw-mt-1 tw-text-ui14 tw-font-light tw-text-[#1A1A1A]'
            )}
          >
            {description}
          </p>
        )}
      </div>
      <button
        type="button"
        className={clsx('tw-block tw-h-5 tw-w-5')}
        aria-label="通知を閉じる"
        onClick={onClose}
      >
        <IconClose boxSize="20" />
      </button>
    </div>
  );
};
