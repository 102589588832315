import { clsx } from 'clsx';
import { toast, type Toast } from 'react-hot-toast';

import { Snackbar } from '~/components/ui/Snackbar';

type Props = {
  content: string;
  option?: Partial<Pick<Toast, 'id' | 'duration'>>;
};

const defaultOption: Partial<Toast> = {
  position: 'bottom-right',
  duration: 6000,
};

const getMessageClass = (isVisible: boolean) =>
  clsx(
    'tw-pointer-events-auto tw-transition',
    isVisible
      ? 'tw-translate-y-0 tw-opacity-100'
      : 'tw-translate-y-5 tw-opacity-0'
  );

const onDismissMessage = () => {
  toast.dismiss();
};

// 成功メッセージ
export const notifySuccess = ({ content, option }: Props) =>
  toast.custom(
    (t) => (
      <div className={getMessageClass(t.visible)}>
        <Snackbar onClose={onDismissMessage}>{content}</Snackbar>
      </div>
    ),
    { ...defaultOption, ...option }
  );

// エラーメッセージ
export const notifyError = ({ content, option }: Props) =>
  toast.custom(
    (t) => (
      <div className={getMessageClass(t.visible)}>
        <Snackbar theme="error" onClose={onDismissMessage}>
          {content}
        </Snackbar>
      </div>
    ),
    { ...defaultOption, ...option }
  );
